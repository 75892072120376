module.exports = {
    siteMetadata: {
        title: `Bat in Web Maciej Szałkowski`,
        author: `Bat in Web`,
        description: "Firma zajmująca się tworzeniem aplikacji www. Zaczynając od stworzenia indywidualnego projektu strony internetowej poprzez jej zakodowanie kończąc na wdrożeniu strony online. Nasze strony są responsywne i utrzymane wg najnowszych standardów",
        siteUrl: "https://batinweb.com",
        image: `/images/logo.png`,
        languages: {
            langs: ["en", "pl"],
            defaultLangKey: "pl"
        }
    },
    plugins: [
        `gatsby-plugin-react-helmet`,
        `gatsby-plugin-sitemap`,
        {
            resolve: `gatsby-source-filesystem`,
            options: {
                name: `images`,
                path: `${__dirname}/src/images`
            }
        },
        {
            resolve: `gatsby-plugin-manifest`,
            options: {
                name: `Bat in Web Maciej Szałkowski`,
                short_name: `Bat in Web`,
                start_url: `/`,
                background_color: `#ffffff`,
                theme_color: `#ffffff`,
                display: `standalone`,
                icon: `src/images/favicon/favicon.png` // This path is relative to the root of the site.
            }
        },
        {
            resolve: "gatsby-plugin-i18n",
            options: {
                langKeyForNull: "en",
                langKeyDefault: "pl",
                useLangKeyLayout: true,
                prefixDefault: false
            }
        },
        {
            resolve: `gatsby-plugin-facebook-pixel`,
            options: {
                pixelId: "2068387979956970"
            }
        },
        {
            resolve: `gatsby-plugin-facebook-sdk`,
            options: {
                appId: "488171331953510",
                status: true,
                xfbml: true,
                version: "v2.7"
            }
        },
        {
            resolve: `gatsby-plugin-google-analytics`,
            options: {
                trackingId: "UA-134605543-1"
            }
        },
        // this (optional) plugin enables Progressive Web App + Offline functionality
        // To learn more, visit: https://gatsby.dev/offline
        // `gatsby-plugin-offline`,
        `gatsby-plugin-sharp`,
        `gatsby-transformer-sharp`,
        `gatsby-plugin-sass`
    ]
};
