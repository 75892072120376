import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import GetConfig from "../../gatsby-config";

const {siteMetadata} = GetConfig;

function SEO({description, language, meta, title}) {

    const metaDescription = description || siteMetadata.description;
    return (
        <Helmet
            htmlAttributes={{lang: `${language}`}}
            title={title}
            titleTemplate={`%s | ${siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription
                },
                {
                    property: `og:title`,
                    content: title
                },
                {
                    property: `og:description`,
                    content: metaDescription
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    property: `og:image`,
                    content: siteMetadata.image
                }
            ].concat(meta)}
        >
            <link rel="stylesheet" href="//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"/>
        </Helmet>
    );
}

SEO.defaultProps = {
    meta: [],
    description: ``
};

SEO.propTypes = {
    description: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    language: PropTypes.string.isRequired,
    title: PropTypes.string
};

export default SEO;
